import axios from 'api/axios'
import type { AxiosResponse } from 'axios'
import type { Lang } from 'types/api'
import type { EntitiesList, Entity, EntityFilters, EntityType, GetEntitiesParams } from 'types/entity'

const EntityApi = {
  /**
   * API to search entities by optional criteria. Result is paged.
   * Security roles - Anybody.
   *
   * @param {number} [data.eventId] - Reference to the existing event.
   * @param {string[]} [data.types] - List of entity types.
   * @param {string[]} [data.acquisitions] - List of acquisition types.
   * @param {number} [data.page] - Page.
   * @param {number} [data.pageSize] - Page size.
   * @param {string} [data.lang] - User’s language.
   *
   * @return {EntitiesList} - List of entities
   */
  async getEntities(data: GetEntitiesParams = {}): Promise<AxiosResponse<EntitiesList>> {
    return axios.post('/public/entities/list/page', data)
  },

  /**
   * API to retrieve entity details. Result is cached.
   * Security roles - Anybody.
   *
   * @param {string} code - Entity code
   * @param {Lang} [lang] - Supported locale code.
   *
   * @return {Entity} - Entity info
   */
  async getEntityByCode(code: string, lang?: Lang): Promise<AxiosResponse<Entity>> {
    return axios.get(`/public/entity/${code}/details`, { params: { lang } })
  },

  /**
   * API to retrieve list of entity types. Result is cached.
   * Security roles - Anybody.
   *
   * @return {Entity} - Entity info
   */
  async getEntityTypes(): Promise<AxiosResponse<EntityType[]>> {
    return axios.get('/public/entity/types')
  },

  /**
   * API to get all available entities per event.
   * Security roles - Anybody.
   *
   * @param {number} id - Event id
   * @param {EntityFilters} [data] - Search criteria.
   *
   * @return {Entity[]} - Entities by id info.
   */
  async getEntitiesListByEvent(id: number, data: EntityFilters = {}): Promise<AxiosResponse<Entity[]>> {
    return axios.post(`/public/event/${id}/entities/list`, data)
  },
}

export default EntityApi
